import React, { useEffect, useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { getSearchVippedItemsUrl } from '../../../utils/helpers';
import {
  locationsQuery,
  searchItemsQuery,
  searchVippedItemsQuery,
  searchVippedResidencesItemsQuery
} from '../../../queries';
import { setChosenLocationNames, setLocationIds } from '../../../actions';
import ItemCard from '../../Cards/ItemCard';
import SectionPlaceholder from '../../Placeholders/SectionPlaceholder';
import ItemCardPlaceholder from '../../Placeholders/ItemCardPlaceholder';
import NewSearch from '../index';
import Section from '../../Section';
import SectionList from '../../SectionList';
import withEndlessScroll from '../../HOC/endlessScroll';
import { SearchPageTitle } from '../../SearchPageTitle';
import FallbackView from '../../FallbackView';
import { useGetBookmarks } from '../../../hooks/useGetBookmarks';

const searchListRef = React.createRef();
const EndlessSearchItems = withEndlessScroll(SectionList);

const NewSearchPageResults = forwardRef(
  ({ transformedObj, setErrorUI, h1, resetForm, totalAdsCount }, ref) => {
    const { t } = useTranslation();
    const params = useSearchParams();
    const dispatch = useDispatch();
    const searchVippedItemsUrl = getSearchVippedItemsUrl(transformedObj.filter);
    const { isBookmarked } = useGetBookmarks();
    const locationIds = useMemo(
      () => params.getAll('location_ids[]'),
      [params.getAll]
    );
    const locationsQueryVariables = {
      scope: 'ALL',
      limit: 1000
    };
    const { data } = useQuery(locationsQuery(locationsQueryVariables), {
      variables: locationsQueryVariables
    });
    const locations = data?.locations || [];
    useEffect(() => {
      if (locationIds.length) {
        const locationNames = locations
          .filter(location => locationIds.includes(location.id))
          .map(location => location.name.trim());
        dispatch(setChosenLocationNames(locationNames));
        dispatch(setLocationIds(locationIds));
      }
    }, [locationIds, data]);

    const router = useRouter();

    useEffect(() => {
      router.beforePopState(({ options }) => {
        // eslint-disable-next-line no-param-reassign
        options.scroll = false;

        return true;
      });
    }, []);

    const searchItemsQueryVariables = {
      first: 16,
      filter: transformedObj.filter,
      sort: transformedObj.sorting || 'BUMPED_AT_DESC'
    };
    const searchVippedQueryVariables = {
      filter: { ...transformedObj.filter, scope: 'VIPPED_PURE' },
      limit: 4,
      sort: 'RANDOM'
    };
    const searchVippedResidencesQueryVariables = {
      filter: { ...transformedObj.filter, scope: 'VIPPED_APARTMENTS' },
      limit: 4,
      sort: 'RANDOM'
    };

    /*
     * Load  data via hooks
     * */
    const [
      {
        loading: searchItemsQueryLoading,
        data: searchItemsQueryData,
        error: searchItemsError,
        fetchMore
      },
      {
        loading: searchVippedItemsQueryLoading,
        data: searchVippedItemsQueryData,
        error: searchVippedItemsQueryError
      },
      {
        loading: searchVippedResidencesItemsQueryLoading,
        data: searchVippedResidencesItemsQueryData,
        error: searchVippedResidencesItemsQueryError
      }
    ] = (() => {
      const searchQuery = useQuery(
        searchItemsQuery(searchItemsQueryVariables),
        {
          variables: searchItemsQueryVariables,
          ssr: false,
          notifyOnNetworkStatusChange: true
        }
      );
      const searchVippedQuery = useQuery(
        searchVippedItemsQuery(searchVippedQueryVariables),
        {
          variables: searchVippedQueryVariables,
          ssr: false,
          notifyOnNetworkStatusChange: true
        }
      );
      const searchVippedResidencesQuery = useQuery(
        searchVippedResidencesItemsQuery(searchVippedResidencesQueryVariables),
        {
          variables: searchVippedResidencesQueryVariables,
          ssr: false,
          notifyOnNetworkStatusChange: true
        }
      );

      return [searchQuery, searchVippedQuery, searchVippedResidencesQuery];
    })();

    /*
     * searchItems
     * */

    const itemsList =
      searchItemsQueryData?.itemsConnection?.edges.map(item => {
        return (
          <ItemCard
            key={item.node.id}
            {...item.node}
            inBookmarks={isBookmarked(item.node.id)}
          />
        );
      }) || [];

    /*
     * vipSearchItems
     * */
    const itemsVipList =
      (searchVippedItemsQueryData?.items &&
        searchVippedItemsQueryData.items.map(item => {
          return (
            <ItemCard
              key={item.id}
              {...item}
              inBookmarks={isBookmarked(item.id)}
            />
          );
        })) ||
      [];

    /*
     * vipResidencesSearchItems
     * */
    const itemsVipResidencesList =
      (searchVippedResidencesItemsQueryData?.items &&
        searchVippedResidencesItemsQueryData.items.map(item => {
          return (
            <ItemCard
              key={item.id}
              {...item}
              inBookmarks={isBookmarked(item.id)}
            />
          );
        })) ||
      [];

    const placeholder = loading => {
      if (!loading) return null;

      return (
        <SectionPlaceholder>
          <ItemCardPlaceholder />
          <ItemCardPlaceholder />
        </SectionPlaceholder>
      );
    };

    useEffect(() => {
      if (
        searchVippedItemsQueryError ||
        searchVippedResidencesItemsQueryError ||
        searchItemsError
      ) {
        setErrorUI(true);
      }
    });

    return (
      <>
        <NewSearch
          formElToAssign={ref}
          totalAdsCount={searchItemsQueryData?.itemsConnection.totalCount}
          isLoading={
            searchVippedItemsQueryLoading ||
            searchVippedResidencesItemsQueryLoading
          }
        />
        {totalAdsCount === 0 ? (
          <FallbackView
            width={182}
            height={225}
            isShowHeader={false}
            onClickHandler={resetForm}
            text={t('search.no_results.text')}
            title={t('search.nothing_found')}
            btnText={t('search.no_results.link')}
            icon="/static/images/new_search/icons/no-results-icon.png"
          />
        ) : (
          <>
            <SearchPageTitle title={h1} />
            {!searchVippedItemsQueryLoading && itemsVipList.length ? (
              <Section
                id="search-page-vipped"
                title={t('sections.vipped.title')}
                url={searchVippedItemsUrl}
                urlTitle={t('sections.vipped.show_all')}
                sectionAdditionalClasses="section-block center-block"
              >
                <SectionList listAdditionalClasses="section-list_hide-excess-items">
                  {itemsVipList}
                </SectionList>
              </Section>
            ) : (
              placeholder(searchVippedItemsQueryLoading)
            )}
            {!searchVippedResidencesItemsQueryLoading &&
            itemsVipResidencesList.length ? (
              <>
                {
                  <Section
                    id="search-page-vipped-residences"
                    title={t('sections.vipped_residences.title')}
                    sectionAdditionalClasses="section-block center-block"
                  >
                    <SectionList listAdditionalClasses="section-list_hide-excess-items">
                      {itemsVipResidencesList}
                    </SectionList>
                  </Section>
                }
              </>
            ) : (
              placeholder(searchVippedResidencesItemsQueryLoading)
            )}
            {!(searchItemsQueryLoading && !itemsList.length) ? (
              <Section
                id="search-page-regular-items"
                title={t('search.ads')}
                sectionAdditionalClasses="section-block center-block"
              >
                <EndlessSearchItems
                  items={itemsList || []}
                  loading={searchItemsQueryLoading}
                  ref={searchListRef}
                  isStopLoad={
                    searchItemsQueryData &&
                    !searchItemsQueryData.itemsConnection.pageInfo.hasNextPage
                  }
                  onLoadMore={() => {
                    const moreSearchItemsQueryVariables = {
                      first: 16,
                      filter: transformedObj.filter,
                      sort: transformedObj.sorting || 'BUMPED_AT_DESC',
                      cursor:
                        searchItemsQueryData.itemsConnection.pageInfo.endCursor
                    };
                    fetchMore({
                      variables: moreSearchItemsQueryVariables
                    });
                  }}
                >
                  {itemsList}
                </EndlessSearchItems>
              </Section>
            ) : (
              <SectionPlaceholder>
                <ItemCardPlaceholder />
                <ItemCardPlaceholder />
              </SectionPlaceholder>
            )}
          </>
        )}
      </>
    );
  }
);

NewSearchPageResults.displayName = 'NewSearchPageResults';

NewSearchPageResults.propTypes = {
  transformedObj: PropTypes.object,
  setErrorUI: PropTypes.func,
  h1: PropTypes.string,
  resetForm: PropTypes.func,
  totalAdsCount: PropTypes.number
};

export default NewSearchPageResults;
